import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "dialog",
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.title,
    "custom-class": _ctx.customClass,
    "show-close": false
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.message), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "title", "custom-class"]))
}