import axios from "axios";

const apiKey = "8r7bmAHkiMGEyPBz6ID9cnpNxlLxHjmvsKKzXVG";
const baseUrl = "https://apis.postcode-jp.com/api/v5/postcodes";

export type ZipRelatedAddress = {
  pref: string;
  city: string;
  town: string;
};

export const getAddressFromZip = async (zip: string): Promise<ZipRelatedAddress | undefined> => {
  const endpoint = `${baseUrl}/${zip}`;
  const headers = {
    "apikey": apiKey,
    "fields": "pref,city,town",
  };
  const res = await axios.get(endpoint, {
    headers,
  });
  if (res.data.length === 0) {
    return undefined;
  }
  return res.data[0] as ZipRelatedAddress
};
