export type ValidateRes = {
  message: string;
  valid: boolean;
}
export const validateStringLength = (v: string, min: number, max: number, fieldName: string): ValidateRes => {
  const valid = min <= v.length && v.length <= max;
  let message = ""
  if (min !== 0 && v.length === 0) {
    message = `${fieldName}は必須です`;
  } else if (!valid) {
    message = `${fieldName}は${min}~${max}文字以内です`;
  }

  return { valid, message };
}

export const validateStringFixedLength = (v: string, len: number, fieldName: string): ValidateRes => {
  const valid = v.length === len;
  let message = ""
  if (!valid) {
    message = `${fieldName}は${len}文字にしてください`;
  }

  return { valid, message };
}

export const regLoginId = /^[a-zA-Z0-9]*$/;
export const validateLoginId = (v: string): ValidateRes => {
  let message = ""
  const validFormat = regPassword.test(v);
  if (!validFormat) {
    message += "ログインIDは半角英数字と記号のみ利用可能です";
  }

  const validLen = validateStringFixedLength(v, 8, "ログインID");
  if (!validLen.valid) {
    message += `\n${validLen.message}`;
  } 

  return { valid: (validFormat && validLen.valid), message };
}

export const validateEmail = (v: string): ValidateRes => {
  const valid = /.+@.+\..+/.test(v);
  let message = ""
  if (!valid) {
    message = `メールアドレスの形式が不正です`;
  }
  return { valid, message };
}

export const regPassword = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;
export const validatePassword = (v: string): ValidateRes => {
  let message = ""
  const validFormat = regPassword.test(v);
  if (!validFormat) {
    message += "パスワードは半角英数字と記号のみ利用可能です";
  }

  const validLen = validateStringLength(v, 1, 255, "パスワード");
  if (!validLen.valid) {
    message += `\n${validLen.message}`;
  } 

  return { valid: (validFormat && validLen.valid), message };
}