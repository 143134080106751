
import { ref, defineComponent } from 'vue';
import { useRepositories } from "@/repository/repositories";
import { prefectures } from "@/util/prefectures";
import { removeQueryParams } from "@/util/strings";
import UploadFileIcon from "@/components/upload-file-icon.vue";
import Dialog from "@/components/dialog.vue";
import type { ElForm } from 'element-plus';
import DocumentIcon from "@/components/document-icon.vue";
import { getAddressFromZip } from "@/util/zip-resolver";
import { DuplicateValueError, FileSizeLimitError } from '@/repository/errors';
import { validateStringLength, validateEmail, validateStringFixedLength, validatePassword } from "@/util/validate";
// for safari 
// https://github.com/nerdyman/react-compare-slider/issues/27#issuecomment-764681269
import { install } from "resize-observer";
import { useRouter } from 'vue-router';
import RegisterConfirmationModal from "./RegisterConfirmationModal.vue";


install();

export type FormData = {
  email?: string;
  emailConfirmation?: string;
  password?: string;
  passwordConfirmation?: string;
  name?: string;
  kana?: string;
  storeName?: string;
  phoneNumber?: string;
  postalCode?: string;
  prefecture?: string;
  city?: string;
  street?: string;
  building?: string;
  deviceName?: string;
  deviceImei?: string;
  coatingImageUuid?: string;
  receiptImageUuid?: string;
  coatingImageUrl?: string;
  receiptImageUrl?: string;
  tosConfirmation?: boolean;
}

type DataType = {
  formData: FormData;
  passwordConfirmationErr?: string;

  prefectures: string[];
  loading: boolean;
  done: boolean;

  conversionId?: string;
  invalidConversion: boolean;

  uploadingCoatingImage: boolean;
  uploadingReceiptImage: boolean;

  coatingImageUrl: string;
  receiptImageUrl: string;
}

const formData = {
  email: undefined,
  emailConfirmation: undefined,
  password: undefined,
  passwordConfirmation: undefined,
  name: undefined,
  kana: undefined,
  storeName: undefined,
  phoneNumber: undefined,
  postalCode: undefined,
  prefecture: undefined,
  city: undefined,
  street: undefined,
  building: undefined,
  deviceName: undefined,
  deviceImei: undefined,
  coatingImageUuid: undefined,
  receiptImageUuid: undefined,
  tosConfirmation: undefined,
};
export default defineComponent({
  name: "user-register",

  components: { UploadFileIcon, Dialog, DocumentIcon, RegisterConfirmationModal },

  setup() {
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const dialog = ref<InstanceType<typeof Dialog>>();
    const registerConfirmationModal = ref<InstanceType<typeof RegisterConfirmationModal>>();
    const repos = useRepositories();
    return {
      repos,
      ruleFormRef,
      dialog,
      registerConfirmationModal,
    }
  },

  data(): DataType {
    return {
      prefectures,
      formData,
      loading: false,
      done: false,
      conversionId: undefined,
      invalidConversion: false,
      uploadingCoatingImage: false,
      uploadingReceiptImage: false,

      coatingImageUrl: "",
      receiptImageUrl: "",
    }
  },

  computed: {
    validateMessage(): (key: string) => string {
      return (key: string): string => {
        switch (key) {
          case "email": {
            if (this.formData.email === undefined) return '';
            const { message } = validateEmail(this.formData.email);
            return message;
          }
          case "emailConfirmation": {
            if (this.formData.email === this.formData.emailConfirmation) return '';
            return "メールアドレスと一致させてください";
          }
          case "password": {
            if (this.formData.password === undefined) return '';
            const { message } = validatePassword(this.formData.password);
            return message;
          }
          case "passwordConfirmation": {
            if (this.formData.password === this.formData.passwordConfirmation) return '';
            return "パスワードと一致させてください";
          }
          case "name": {
            if (this.formData.name === undefined) return '';
            const { message } = validateStringLength(this.formData.name, 1, 255, "氏名");
            return message;
          }
          case "kana": {
            if (this.formData.kana === undefined) return '';
            const { message } = validateStringLength(this.formData.kana, 1, 255, "フリガナ");
            return message;
          }
          case "phoneNumber": {
            if (this.formData.phoneNumber === undefined) return '';
            const { message } = validateStringLength(this.formData.phoneNumber, 1, 255, "電話番号");
            return message;
          }
          case "postalCode": {
            if (this.formData.postalCode === undefined) return '';
            const { message } = validateStringFixedLength(this.formData.postalCode, 7, "郵便番号");
            return message;
          }
          case "prefecture": {
            if (this.formData.prefecture === undefined) return '';
            const { valid } = validateStringLength(this.formData.prefecture, 1, 4, "都道府県");
            return valid ? "" : "都道府県は必須です";
          }
          case "city": {
            if (this.formData.city === undefined) return '';
            const { message } = validateStringLength(this.formData.city, 1, 20, "市町村");
            return message;
          }
          case "street": {
            if (this.formData.street === undefined) return '';
            const { message } = validateStringLength(this.formData.street, 1, 20, "丁番");
            return message;
          }
          case "building": {
            if (this.formData.building === undefined) return '';
            const { valid } = validateStringLength(this.formData.building, 0, 50, "建物名");
            return valid ? "" : "建物名は50文字までです";
          }
          case "deviceName": {
            if (this.formData.deviceName === undefined) return '';
            const { message } = validateStringLength(this.formData.deviceName, 1, 255, "端末名");
            return message;
          }
          case "deviceImei": {
            if (this.formData.deviceImei === undefined) return '';
            const { message } = validateStringLength(this.formData.deviceImei, 1, 20, "端末IMEI");
            return message;
          }
          case "storeName": {
            if (this.formData.storeName === undefined) return '';
            const { message } = validateStringLength(this.formData.storeName, 1, 255, "施工店舗名");
            return message;
          }
          case "coatingImageUuid": {
            if (this.formData.coatingImageUuid === undefined) return '';
            const { valid } = validateStringLength(this.formData.coatingImageUuid, 1, 255, "コーティング済みのスマホ画面画像");
            return valid ? "" : "コーティング済みのスマホ画面画像は必須です";
          }
          case "receiptImageUuid": {
            if (this.formData.receiptImageUuid === undefined) return '';
            const { valid } = validateStringLength(this.formData.receiptImageUuid, 1, 255, "ハルトコーティングplusの申し込みが確認できる画像");
            return valid ? "" : "ハルトコーティングplusの申し込みが確認できる画像は必須です";
          }
          case "tosConfirmation": {
            if (this.formData.tosConfirmation === undefined) return '';
            const valid = this.formData.tosConfirmation === true;
            return valid ? "" : "利用規約に同意してください"
          }
          default: {
            throw new Error("unexpected validation target");
          }
        }
      };
    },
  },

  methods: {

    async onChangeCoatingImage(e: any): Promise<void> {
      this.uploadingCoatingImage = true;

      try {
        const file = e.target.files[0];
        const urls = await this.repos.uploadImageUrl.getUploadImageUrl(file.size ?? 0, 1);
        await fetch(urls.coatingImageUrl ?? "", {
          method: "PUT",
          body: file,
          headers: { "X-Amz-Acl": "public-read" }
        })
          .then(response => {
            if (!response.ok) {
              console.error('response.ok:', response.ok);
              console.error('esponse.status:', response.status);
              console.error('esponse.statusText:', response.statusText);
              throw new Error(response.statusText);
            }
          });

        this.formData.coatingImageUuid = urls.coatingUuid ?? "";
        this.coatingImageUrl = removeQueryParams(urls.coatingImageUrl ?? "");
        this.formData.coatingImageUrl = this.coatingImageUrl;
      } catch (e) {
        if (e instanceof FileSizeLimitError) {
          this.showFileSizeLimitErrorDialog();
        }
        this.uploadingCoatingImage = false;
        throw e;
      }

      this.uploadingCoatingImage = false;
    },
    async onChangeReceiptImage(e: any): Promise<void> {
      this.uploadingReceiptImage = true;

      try {
        const file = e.target.files[0];
        const urls = await this.repos.uploadImageUrl.getUploadImageUrl(1, file.size ?? 0);
        await fetch(urls.receiptImageUrl ?? "", {
          method: "PUT",
          body: file,
          headers: { "X-Amz-Acl": "public-read" }
        })
          .then(response => {
            if (!response.ok) {
              console.error('response.ok:', response.ok);
              console.error('esponse.status:', response.status);
              console.error('esponse.statusText:', response.statusText);
              throw new Error(response.statusText);
            }
          });

        this.formData.receiptImageUuid = urls.receiptUuid ?? "";
        this.receiptImageUrl = removeQueryParams(urls.receiptImageUrl ?? "");
        this.formData.receiptImageUrl = this.receiptImageUrl;
      } catch (e) {
        if (e instanceof FileSizeLimitError) {
          this.showFileSizeLimitErrorDialog();
        }
        this.uploadingReceiptImage = false;
        throw e;
      }

      this.uploadingReceiptImage = false;
    },
    onClickFillAddress(): void {
      try {
        if (this.formData.postalCode === undefined || this.formData.postalCode.length === 0) return;
        getAddressFromZip(this.formData.postalCode)
          .then((res) => {
            this.formData.prefecture = res?.pref ?? "";
            this.formData.city = `${res?.city ?? ""}${res?.town ?? ""}`;
          });
      } catch (e) {
        console.error("failed to fill address. err: ", e);
      }
    },
    showSuccessDialog() {
      this.dialog?.open("登録完了", "登録が完了しました。5秒後にログイン画面へ移動します。", false);
      setTimeout(() => {
        this.$router.push("/login");
      }, 5000);
    },
    showFailedDialog() {
      this.dialog?.open("登録失敗", "予期せぬ不具合が発生しました。通信環境を確認して再度お試しください。", true);
    },
    showValidationErrorDialog() {
      this.dialog?.open("入力内容に誤りがあります", "入力内容をご確認の上再度お試しください。", true);
    },
    showDuplicateErrorDialog() {
      this.dialog?.open("登録失敗", "端末IMEIが登録済みの内容です。端末IMEIをご確認の上再度お試しください。", true);
    },
    showFileSizeLimitErrorDialog() {
      this.dialog?.open("画像アップロード失敗", "アップロードした画像が大きすぎます。 (最大20M) ", true);
    },
    modifyFormDataForValidate(): void {
      if (this.formData.email === undefined) {
        this.formData.email = "";
      }
      if (this.formData.emailConfirmation === undefined) {
        this.formData.emailConfirmation = "";
      }
      if (this.formData.password === undefined) {
        this.formData.password = "";
      }
      if (this.formData.passwordConfirmation === undefined) {
        this.formData.passwordConfirmation = "";
      }
      if (this.formData.name === undefined) {
        this.formData.name = "";
      }
      if (this.formData.kana === undefined) {
        this.formData.kana = "";
      }
      if (this.formData.phoneNumber === undefined) {
        this.formData.phoneNumber = "";
      }
      if (this.formData.postalCode === undefined) {
        this.formData.postalCode = "";
      }
      if (this.formData.prefecture === undefined) {
        this.formData.prefecture = "";
      }

      if (this.formData.city === undefined) {
        this.formData.city = "";
      }
      if (this.formData.street === undefined) {
        this.formData.street = "";
      }
      if (this.formData.deviceName === undefined) {
        this.formData.deviceName = "";
      }
      if (this.formData.deviceImei === undefined) {
        this.formData.deviceImei = "";
      }
      if (this.formData.storeName === undefined) {
        this.formData.storeName = "";
      }
      if (this.formData.coatingImageUuid === undefined) {
        this.formData.coatingImageUuid = "";
      }
      if (this.formData.receiptImageUuid === undefined) {
        this.formData.receiptImageUuid = "";
      }
      if (this.formData.tosConfirmation === undefined) {
        this.formData.tosConfirmation = false;
      }
    },
    isValidFormData(): boolean {
      let res = validateEmail(this.formData.email ?? "");
      if (!res.valid) return false;
      let validEmailConfirmation = this.formData.email === this.formData.emailConfirmation;
      if (!validEmailConfirmation) return false;

      res = validatePassword(this.formData.password ?? "");
      if (!res.valid) return false;
      let validPasswordConfirmation = this.formData.password === this.formData.passwordConfirmation;
      if (!validPasswordConfirmation) return false;

      res = validateStringLength(this.formData.name ?? "", 1, 255, "氏名");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.kana ?? "", 1, 255, "フリガナ");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.phoneNumber ?? "", 1, 255, "電話番号");
      if (!res.valid) return false;
      res = validateStringFixedLength(this.formData.postalCode ?? "", 7, "郵便番号");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.prefecture ?? "", 1, 4, "都道府県");
      console.log(res, this.formData.prefecture);
      if (!res.valid) return false;
      res = validateStringLength(this.formData.city ?? "", 1, 20, "市町村");
      if (!res.valid) return false;

      res = validateStringLength(this.formData.street ?? "", 1, 20, "丁番");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.building ?? "", 0, 50, "建物名");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.deviceName ?? "", 1, 255, "端末名");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.deviceImei ?? "", 1, 20, "端末IMEI");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.storeName ?? "", 0, 50, "施工店舗名");

      if (!res.valid) return false;
      res = validateStringLength(this.formData.coatingImageUuid ?? "", 1, 255, "コーティング済みのスマホ画面画像");
      if (!res.valid) return false;
      res = validateStringLength(this.formData.receiptImageUuid ?? "", 1, 255, "ハルトコーティングplusの申し込みが確認できる画像");
      if (!res.valid) return false;

      if (this.formData.tosConfirmation === undefined || !this.formData.tosConfirmation) return false;

      return true;
    },

    showRegisterConfirmation(): void {
      this.modifyFormDataForValidate();
      if (!this.isValidFormData()) {
        this.showValidationErrorDialog();
        throw new Error(`validate error for registration. formData: ${this.formData}`);
      }
      this.registerConfirmationModal?.open(this.formData);
    },

    onBack(): void {
      this.registerConfirmationModal?.close();
    },

    async register(): Promise<void> {
      this.modifyFormDataForValidate();
      if (!this.isValidFormData()) {
        this.showValidationErrorDialog();
        return;
      }
      try {
        console.log("start register")
        this.loading = true;
        await this.repos.insuranceContractor.register(
          {
            email: this.formData.email,
            name: this.formData.name,
            kana: this.formData.kana,
            phoneNumber: this.formData.phoneNumber,
            postalCode: this.formData.postalCode,
            prefecture: this.formData.prefecture,
            city: this.formData.city,
            street: this.formData.street,
            building: this.formData.building,
            deviceName: this.formData.deviceName,
            deviceImei: this.formData.deviceImei,
            conversionId: this.conversionId,
            storeName: this.formData.storeName,
          },
          this.formData.password ?? "",
          this.formData.coatingImageUuid ?? "",
          this.formData.receiptImageUuid ?? "",
        )
        this.formData = {};
        this.coatingImageUrl = "";
        this.receiptImageUrl = "";
        this.loading = false;
        this.showSuccessDialog();
      } catch (e) {
        if (e instanceof DuplicateValueError) {
          this.showDuplicateErrorDialog();
          this.loading = false;
          return;
        }

        console.error(e);
        this.loading = false;
        this.showFailedDialog();
        return;
      }
    },

    _setConversionId(): void {
      this.conversionId = this.$route.query.rpl as string;
      if (this.conversionId === undefined || this.conversionId.length === 0) {
        this.invalidConversion = true;
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.path === "/login") {
      const router = useRouter()
      return router.push({ path: "/login" });
    }

    next();
  },

  mounted(): void {
    this._setConversionId();
  }
});
