
import { defineComponent } from 'vue';

type DataType = {
  visible: boolean;
  title: string;
  message: string;
  err: boolean;
}

export default defineComponent({
  name: "custom-dialog",
  data(): DataType {
    return {
      visible: false,
      title: "",
      message: "",
      err: false,
    };
  },
  computed: {
    customClass(): string | undefined {
      return this.err ? "err" : "";
    }
  },
  methods: {
    open(title: string, message: string, err: boolean): void {
      this.title = title;
      this.message = message;
      this.err = err;
      this.visible = true;
    },
    close(): void {
      this.title = "";
      this.message = "";
      this.visible = false;
    }
  }
});
