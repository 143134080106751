
import { defineComponent } from 'vue';
import { FormData } from "./Register.vue";
import DocumentIcon from "@/components/document-icon.vue";

const formData = {
  email: undefined,
  password: undefined,
  passwordConfirmation: undefined,
  name: undefined,
  kana: undefined,
  storeName: undefined,
  phoneNumber: undefined,
  postalCode: undefined,
  prefecture: undefined,
  city: undefined,
  street: undefined,
  building: undefined,
  deviceName: undefined,
  deviceImei: undefined,
  coatingImageUuid: undefined,
  receiptImageUuid: undefined,
  tosConfirmation: undefined,
};

type DataType = {
  formData?: FormData
  visible: boolean;
}

export default defineComponent({
  name: "user-register-confirmation-modal",

  components: { DocumentIcon },

  data(): DataType {
    return {
      formData,
      visible: false,
    }
  },

  methods: {
    open(formData: FormData): void {
      this.formData = formData;
      this.visible = true;
    },
    close(): void {
      this.formData = undefined;
      this.visible = false;
    }
  }
});
